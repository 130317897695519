.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: transform 0.3s, background 0.3s;
  background: linear-gradient(99deg, #96efb6, #6ccbf7);
}

.user-item a:hover,
.user-item a:active {
  
  background: linear-gradient(9deg, #6ffaa2, #4bc1f8);
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: white;
}
.user-item:hover, .user-item:active{
  transform: translateY(-3px) scale(1.1);/*3d effect*/
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: rgb(253, 252, 253);

  font-weight: bolder;
}

.user-item__info h3 {
  margin: 0;
}