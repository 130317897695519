.place-item {
  margin: 1rem 0;
}

.place-item__content {
  padding: 0;
  background: linear-gradient(90deg,#96efb6, #6ccbf7);
  color: white;
}

.place-item__info {
  padding: 1rem;
  text-align: center;
  
}

.place-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.place-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-item__info h2,
.place-item__info h3,
.place-item__info p {
  margin: 0 0 0.5rem 0;
}

.place-item__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid #ccc; */
}

.place-item__modal-content {
  padding: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.place-item__actions button,
.place-item__actions a {
  margin: 0.5rem;
}
.map-container{
  height: 11rem;
  width: 100%;
}

@media (min-width: 768px) {
  .place-item__image {
    height: 20rem;
  } 
}