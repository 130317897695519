.place-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgb(251, 249, 249);
  border-radius: 10px;
  background: linear-gradient(9deg, #84fab0, #8fd3f4);;
}
