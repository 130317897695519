@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #e0f146;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: linear-gradient(90deg,#96efb6, #6ccbf7);
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  transition: transform 0.3s, background 0.3s;
  background: linear-gradient(99deg, #96efb6, #6ccbf7);
}

.user-item a:hover,
.user-item a:active {
  
  background: linear-gradient(9deg, #6ffaa2, #4bc1f8);
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: white;
}
.user-item:hover, .user-item:active{
  transform: translateY(-3px) scale(1.1);/*3d effect*/
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: rgb(253, 252, 253);

  font-weight: bolder;
}

.user-item__info h3 {
  margin: 0;
}
.users-list{
    list-style-type: none;
    margin : 0 auto;
    padding: 0;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ffffff;
  border-color: #73c5f8;
  box-shadow: 0 0 10px gray;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #e5dddf;
  border-radius: 4px;
  background:  white;
  color:#6ccbf7 ;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}
/*edit button hover*/
.button:hover,
.button:active {
  
  background: white;
  border-color: white;
  font-weight: bold;
  color:#6ccbf7 ;
  /* box-shadow: 0 0 20px white; */
}
/*view on map button*/
.button--inverse {
  /* background: transparent;
  color: white; */
  color:#6ccbf7 ;
  /* background: linear-gradient(9deg, #6ffaa2, #4bc1f8); */
  background: white;
  
}

.button--inverse:hover,
.button--inverse:active {
  color:#6ccbf7 ;
  /* background: linear-gradient(9deg, #6ffaa2, #4bc1f8); */
  background: white;
  box-shadow: 0 0 20px white;
}

.button--danger {
  background: white;
  /* border-color: #e23e3e; */
  color:#6ccbf7 ;
}

.button--danger:hover,
.button--danger:active {
  background: red;
  border-color: red;
  box-shadow: 0 0 20px white;
  font-weight: bold;
  color:white;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
  box-shadow: 0 0 20px white;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.place-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgb(251, 249, 249);
  border-radius: 10px;
  background: linear-gradient(9deg, #84fab0, #8fd3f4);;
}

.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background:linear-gradient(9deg, #84fab0, #8fd3f4);
  /*box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);*/
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
  --c1: #84fab0;
  --c2: #8fd3f4;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}
.nav-links li button{
  text-decoration: none;
}
.nav-links a {
  text-transform: uppercase;
  background: transparent;
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  transition: transform 0.3s, background 0.3s;


}
.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {  
  transform: translateY(-20px) scale(1.1); 
  font-weight: bold;
   border: 2px solid white; 
  box-shadow: 0px 0px 8px white;
  border-radius: 10px; 
}

.nav-links button {
  /* cursor: pointer;
    color: white;
  background: transparent;
  padding: 0.5rem;
  font: inherit; */
  text-transform: uppercase;
  background: transparent;
  color: white;
  text-decoration: none;
  border:none;
  padding: 0.5rem;
  transition: transform 0.3s, background 0.3s;

}

.nav-links button:focus {
  border-color: white;
  border: 2px solid white; /* Ensure the border remains white */
  border-radius: 10px;

}

.nav-links button:hover,
.nav-links button:active {
  /* background: transparent; */
  transform: translateY(-1px) scale(1);
  border: 2px solid white;
  box-shadow: 0px 0px 8px white;
  font-weight: bold;
   /* Ensure the border remains white */
  border-radius: 10px;


 
  
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    /* border: 1px solid white; */
    cursor: pointer;
  
  color: white;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    
    color: white;
    background: transparent;
    border: 2px solid white; 
  
  

  }
}

.side-drawer{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.modal {
  z-index: 100;
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: white;
  color: #6ccbf7;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
  text-align: center;
  color: #6ccbf7;
}

.modal__footer {
  padding: 1rem 0.5rem;
  background: linear-gradient(90deg,#96efb6, #6ccbf7);
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.map{
    width: 100%;
    height: 100%;
}
.place-item {
  margin: 1rem 0;
}

.place-item__content {
  padding: 0;
  background: linear-gradient(90deg,#96efb6, #6ccbf7);
  color: white;
}

.place-item__info {
  padding: 1rem;
  text-align: center;
  
}

.place-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.place-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-item__info h2,
.place-item__info h3,
.place-item__info p {
  margin: 0 0 0.5rem 0;
}

.place-item__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid #ccc; */
}

.place-item__modal-content {
  padding: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.place-item__actions button,
.place-item__actions a {
  margin: 0.5rem;
}
.map-container{
  height: 11rem;
  width: 100%;
}

@media (min-width: 768px) {
  .place-item__image {
    height: 20rem;
  } 
}
.place-list {
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  max-width: 40rem;
}

.authentication__header {
  color: rgb(243, 247, 246);
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}
