.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}
.nav-links li button{
  text-decoration: none;
}
.nav-links a {
  text-transform: uppercase;
  background: transparent;
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: transform 0.3s, background 0.3s;


}
.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {  
  transform: translateY(-20px) scale(1.1); 
  font-weight: bold;
   border: 2px solid white; 
  box-shadow: 0px 0px 8px white;
  border-radius: 10px; 
}

.nav-links button {
  /* cursor: pointer;
    color: white;
  background: transparent;
  padding: 0.5rem;
  font: inherit; */
  text-transform: uppercase;
  background: transparent;
  color: white;
  text-decoration: none;
  border:none;
  padding: 0.5rem;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: transform 0.3s, background 0.3s;

}

.nav-links button:focus {
  border-color: white;
  border: 2px solid white; /* Ensure the border remains white */
  border-radius: 10px;

}

.nav-links button:hover,
.nav-links button:active {
  /* background: transparent; */
  transform: translateY(-1px) scale(1);
  border: 2px solid white;
  box-shadow: 0px 0px 8px white;
  font-weight: bold;
   /* Ensure the border remains white */
  border-radius: 10px;


 
  
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    /* border: 1px solid white; */
    cursor: pointer;
  
  color: white;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    
    color: white;
    background: transparent;
    border: 2px solid white; 
  
  

  }
}
