.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #e5dddf;
  border-radius: 4px;
  background:  white;
  color:#6ccbf7 ;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}
/*edit button hover*/
.button:hover,
.button:active {
  
  background: white;
  border-color: white;
  font-weight: bold;
  color:#6ccbf7 ;
  /* box-shadow: 0 0 20px white; */
}
/*view on map button*/
.button--inverse {
  /* background: transparent;
  color: white; */
  color:#6ccbf7 ;
  /* background: linear-gradient(9deg, #6ffaa2, #4bc1f8); */
  background: white;
  
}

.button--inverse:hover,
.button--inverse:active {
  color:#6ccbf7 ;
  /* background: linear-gradient(9deg, #6ffaa2, #4bc1f8); */
  background: white;
  box-shadow: 0 0 20px white;
}

.button--danger {
  background: white;
  /* border-color: #e23e3e; */
  color:#6ccbf7 ;
}

.button--danger:hover,
.button--danger:active {
  background: red;
  border-color: red;
  box-shadow: 0 0 20px white;
  font-weight: bold;
  color:white;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
  box-shadow: 0 0 20px white;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}